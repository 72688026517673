import React, { Component, useState, useEffect } from "react"
import { propTypes } from "react-bootstrap/esm/Image"
import paragraph from './../paragraph/paragraph'

export default function Buttton(props) {
    return (
      <button 
      style={{
        backgroundColor:'#529065',
        width:!props.width ? "30%": props.width,
        height: '6vh',
        borderRadius:10,
        border:0,
        outline:'none',
        marginTop:props.marginTop,
        marginBottom:props.marginBottom
      }}
      onClick={
        props.onClick
      }
      >
        <paragraph style={{color:'white',fontSize:18}}>
        <b>{props.text}</b>
        </paragraph>
      </button>
    )
}


