import React, { Component, useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import Image from "react-bootstrap/Image";
import Card from "./../../components/card/card";
import { useMediaQuery } from "react-responsive";
import Button from "./../../components/button/button";
import Header1 from "./../../components/header1/header1";
import Paragraph from "../../components/paragraph/paragraph";
import ImageBlock from "./../../components/imageBlock/imageBlock";
import Map from "./../../components/map/map";
import Nav from "./../../components/navbar/navbar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Footer from "./../../components/footer/footer";
import { isWidthDown } from "@material-ui/core";

var headerImage = require("./../../assets/mtns.jpg");
const padding = 30;

export default function Results() {
  return (
    <div>
      <Nav selected="Projects" />
      <Hidden mdUp>
        <div style={{ width: "100%", height: "70vh" }}>
          <img
            src={headerImage}
            style={{
              height: "70vh",
              width: "100%",
              objectFit: "cover",
              position: "absolute",
            }}
          />
          <div style={{ position: "absolute", height: "70vh", width: "100%" }}>
            <Grid
              container
              style={{
                paddingLeft: padding,
              }}
            >
              <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
                xl={7}
                style={{
                  height: "70vh",
                  width: "100%",
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Header1
                    style={{
                      color: "white",
                      fontSize: "5vh",
                    }}
                  >
                    Preserve Forests.
                    <br />
                    <br />
                    Remove Carbon.
                    <br />
                    <br />
                    Restore our Climate.
                    <br />
                    <br />
                  </Header1>
                </div>
              </Grid>
              <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            </Grid>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            padding: padding,
          }}
        >
          <Grid container>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
             <Header1 style={{ color: "#22422b" }}>
                Our Projects
              </Header1>

              <Paragraph>
                Carbon Down <b>restores and protects forests </b> around the United States.
                <br/>
                <br/>
                We're constantly looking to find and create more projects, so keep your eyes open for upcoming projects. 
                <br/>
                <br/>
                <hr/>
                <br/>
              </Paragraph>
              
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card 
                onClick={() => navigate("/projects/middlebury", { replace: false })}
                img={require("./../../assets/middlebury/middleburySummary.jpg")}
                title="Middlebury College"
                type="Improved Forest Management"
                location="Central Vermont"
              />
              <Card 
                onClick={() => navigate("/projects/hudson", { replace: false })}
                img={require("./../../assets/hudsonSummary.jpg")}
                title="Hudson Farms"
                type="Improved Forest Management"
                location="Northern New Jersey"
              />
              <Card 
                onClick={() => navigate("/projects/tricity", { replace: false })}
                img={require("./../../assets/tricity/tricitySummary.jpg")}
                title="Tricity"
                type="Improved Forest Management"
                location="Northern Massachusetts"
              />
           
            </Grid>
          </Grid>
        </div>
      </Hidden>
      <Hidden smDown>
        
          <Grid container>
            <Grid item md={6} lg={4} xl={4} style={{padding:padding}}>
              <div style={{height:'80vh', overflow:'auto', padding:10}}>
              <Header1 style={{ color: "#22422b" }}>
                Our Projects
              </Header1>
              <Paragraph>
                Carbon Down <b>restores and protects forests </b> around the United States.
                <br/>
                <br/>
                We're constantly looking to find and create more projects, so keep your eyes open for upcoming projects.
                <br/>
                <br/>
                <hr/>
                <br/>
              </Paragraph>
              <Card 
                onClick={() => navigate("/projects/middlebury", { replace: false })}
                img={require("./../../assets/middlebury/middleburySummary.jpg")}
                title="Middlebury College"
                type="Improved Forest Management"
                location="Central Vermont"
              />
              <Card 
                onClick={() => navigate("/projects/hudson", { replace: false })}
                img={require("./../../assets/hudsonSummary.jpg")}
                title="Hudson Farms"
                type="Improved Forest Management"
                location="Northern New Jersey"
              />
              <Card 
                onClick={() => navigate("/projects/tricity", { replace: false })}
                img={require("./../../assets/tricity/tricitySummary.jpg")}
                title="Tricity"
                type="Improved Forest Management"
                location="Northern Massachusetts"
              />
              </div>
            </Grid>
            <Grid item md={6} lg={8} xl={8}>
              <Map />
            </Grid>
          </Grid>

        
      </Hidden>
      <Footer />
    </div>
  );
}
